<template>
  <div>
    <nav-bar class="sticky top-0 z-10" :title="plot.title" />

    <main class="px-2 z-0 divide-y-8 divide-blue font-wsbook lg:w-3/6 mx-auto">
      <div v-if="$apollo.queries.plotContent.loading">Loading...</div>
      <div v-if="error">{{ error }}</div>
      <!-- <div>{{plotTags.nodes[0].plots.nodes[0].content}}</div> -->
      <div
        class="content font-wsbold text-base leading-5 py-10"
        v-html="plot.content"
      ></div>
      <div class="py-10">
        <div class="pb-10">
          The caretakers will take you on a journey through this narrative in
          different ways:
        </div>
        <div
          class="pr-4 pb-4"
          v-for="(type, index) in caretakers[ this.$route.params.plot ]"
          :key="index"
        >
          <a
            :href="
              '/plots/' +
              plot.title.toLowerCase() +
              '/caretakers/' +
              caretaker(type).slug
            "
          >
            <div class="font-wsmedium text-lg">{{ caretaker(type).name }}</div>
            <div class="flex justify-start">
              <img
                class="w-24 h-24 flex-shrink-0"
                :src="`/static/${caretaker(type).slug}.svg`"
                :alt="caretaker(type).name"
              />
              <span class="pl-3 pt-2">
                {{ caretaker(type).description }}
              </span>
            </div>
          </a>
        </div>
      </div>
      <div class="py-10">
        <!-- community members -->
        <div class="w-4/6 pb-4">
          The people of community who contribute to
          <span class="uppercase">{{ plot.title }}</span
          >:
        </div>
        <div class="flex flex-wrap">
          <div
            class=" mr-4 pb-4"
            v-for="(member, index) in communitymembers"
            :key="index"
          >
            <!-- <router-link :to="'/plots/' +  $route.params.plot + '/caretakers/' + member.caretakertype + '#' + member.id"> -->
              <img class="w-24" :src="`/static/communitypeople.svg`" />
            <!-- </router-link> -->
            
            <div class="font-wsmedium text-lg">{{ member.initials }}</div>
          </div>
        </div>
        <div class="pb-4">
          Now let’s go back to the homepage and start the journey by clicking
          the caretakers.
        </div>
      </div>
    </main>

    <!-- <main class="container px-2 pt-24 mx-auto md:w-6/12"> -->
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { GET_PLOT_QUERY, GET_CARETAKER_TYPES_QUERY } from "../constants/graphql";

export default {
  name: "Plot",
  components: {
    NavBar,
  },
  methods: {
    caretaker(type) {
      let result = this.caretakerTypes.find( caretakerType => caretakerType.slug === type );
      return result? result: {};
    },  
  },
  computed: {
    communitymembers() {
      let list = [];
      if (this.plotContent.communityMembers != undefined) {
        this.plotContent.communityMembers.edges.forEach((edge) => {
          list.push({
            initials: edge.node.communityMemberDetails.initials,
            caretakertype: edge.node.communityMemberDetails.caretakerType.name,
            id : edge.node.databaseId
          });
          //initials.push(node.communityMemberDetails.initials);
        });
      }
      return list;
    },
    plot() {
      let content = {
          title: this.$route.params.plot,
          content: "coming soon",        
      }
      if (this.plotContent && this.plotContent.plots != undefined) {
         if (this.plotContent.plots.nodes.length > 0) {
           content = this.plotContent.plots.nodes[0];
         }
      }
      return content;
    },
  },
  data() {
    return {
      plotContent: {

      },
      caretakerTypes:[],
      error: null,
      caretakers: {
        "plot001" : ["storyteller", "illustrator","planner","architect","broadcaster","navigator"],
        "plot002" : ["storyteller", "illustrator","planner","architect","broadcaster","navigator"],
        "plot003" : ["storyteller", "illustrator","planner","architect","broadcaster","navigator"],
        "plot004" : ["storyteller", "illustrator","planner","architect","broadcaster","navigator"],
        "plot005" : ["storyteller", "illustrator","planner","architect","broadcaster","navigator"],
        "plot006" : ["storyteller", "curator","librarian","architect","broadcaster","photographer"],

      }
    };
  },
  mounted() {
    // console.log(this.plotTags)
    //console.log(this.$route.params)
  },
  apollo: {
    caretakerTypes: {
      query: GET_CARETAKER_TYPES_QUERY,
      update(data) {
        return data.caretakerTypes.nodes;
      },
      //  update: data => data.hello,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    plotContent: {
      // graphql query
      query: GET_PLOT_QUERY,
      update(data) {
        return data.plotTags.nodes[0];
      },
      variables() {
        // Use vue reactive properties here
        return {
          plot: this.$route.params.plot,
        };
      },
      //  update: data => data.hello,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page {
}

.content >>> p {
  color: #031d87;
}

.content >>> .wp-block-image {
  margin-bottom: 10px;
}

.content >>> .wp-block-image {
  background-color: #ffffff;
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.content >>> .wp-block-image img {
  filter: grayscale(100%) contrast(1) blur(0px);
  flex: 1 0 100%;
  height: 100%;
  max-width: 100%;
  mix-blend-mode: multiply;
  object-fit: cover;
  opacity: 1;
  position: relative;
  width: 100%;
}

.content >>> .wp-block-image::before {
  background-color: #001689;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  mix-blend-mode: screen;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
</style>
